import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.1.0</Title>
    <Title order={3} fs="bold">13E-842-244-164 (13th Eon, 842nd Megaannum, 244th Millenia, 164th Year)
    </Title>
    <Text>{`The footsteps were heavy against the metallic floor of the think tank. Alyx didn’t even turn their head from their blueprints before telling the android, “I have some calculations I want to pass by you, could you-”

“I think there’s something you’d like to see,” the android interrupted. Startled, Alyx turned around. Their brows furrowed as they looked into a pair of eyes that matched their own, down to the finest detail. They basked in the odd situation for a moment, checking every feature of this similar being.

Alyx approached slowly, not once moving their gaze, “What is this?”

“I’m you from another time. I’m surprised you’re alive,” the alternate version of Alyx retorted. Native Alyx halted for a moment. Their expression faded between surprise, disgust, and finally excitement.

The android scanned each of them, one at a time. Its robotic motions carried a single finger into the air, perfectly perpendicular with the ground. “I will refer to you,” it gestured towards the Alyx it spent a walk with, “as Alyx. And you,” gesturing to the one that just appeared, “as Ploht. Let me know if you’d prefer another title.”

Both Alyx and Ploht looked at the android’s beaming orange eyes, then back at each other, nodding in synchrony. The robot had no moving facial features, yet it seemed somewhat proud of itself, resting its arms smugly behind its back, awaiting a command.

Alyx turned to their desk, clicking at a touch sensitive pad a couple of times. It let out an affirming beep before lines drew themselves into the adjacent wall, summoning a door from what looked like nothing. The door receded into the floor before a chair piloted itself to Ploht. Unphased by the technology, Ploht and Alyx each took a seat.

“Exhilerated to see that we discovered time travel. Here I was so confident that it was a paradoxical mess,” Alyx snickered unto themselves.

“You didn’t,” Ploht stated dryly. Alyx’s face melted into a grimace, stressing the features of their well-worn face. Ploht took a brief moment to look them over; their age told them apart. Ploht’s skin was youthful and new, Alyx’s was shriveled and their movement was laborious. They weren’t dead in this timeline, but perhaps they were on their way. Alyx must have been nearly a thousand years old.

“You’re not from where I’m from,” Ploht concluded. They cocked their head, taking a moment to ponder, “Though I guess we do have some assemblance of similar history.”

Alyx shifted uncomfortably in their seat. “I don’t understand.”

Ploht lifted themself from their seat and maneuvered to Alyx’s desk, picking up a pencil ready to scribe something down. They stopped, however, after peering at the most recent drawing: a line that broke into three at a dotted point. They set the pencil back down, opting to use Alyx’s drawing instead.

“In layman’s terms, I’m from here,” they pointed at the top-most line, “and you’re from here,” as they pointed at the bottom line. Alyx peered through their own notes then back at Ploht’s finger.

“So we’re both from here,” Alyx placed a finger on the initial line that the others spawned from. Ploht nodded in agreement, and Alyx’s eyes widened in surprise. “That’s amazing!” They stood up quickly, looking at the android with excitement.

A brief silence filled the room. The automaton studied each one, then raised its hands awkwardly, “Celebration!”

Another brief silence.

Alyx turned back, “Then tell me, dear me, what’s the difference? Did your people fail to harness the Phexython? Or rather, did they harness it in some other fashion?”

Ploht was perplexed by this question. Their cold gaze seemed to waiver for a mere moment, “The Phexython? What would you know about the Phexython, and moreso, what about harvesting it?” It dawned on Ploht how calmly this older version of themself was taking it. Although, knowing themself, it wasn’t all too surprising.

They prepped themselves to speak, but the robot lifted its hand. Instead Alyx retreated, allowing it to explain, “The Phexython was a behemoth that was found without an initial objective.” A three-dimensional hologram of the creature’s carcass was laid out on the large open floor amidst them all. “The Hellion I had attempted and failed to escape Planetary Body 0, known as Pia at the time, prior to the discovery of this entity, and the plans for the Hellion II were discarded upon it being found. Instead, they were replaced with the Hyperion I, a ship that would utilize some of the phenomenal components that were harvested.” The imagery was replaced with an astounding interplanetary shuttle, one that even Ploht was impressed by.

Ploht rubbed their fingers to their chin, thinking about the world they recently appeared in. It didn’t take long to realize that they weren’t on Pia, but another planet entirely? They hadn’t considered that as a potential outcome until this point. The sky sure was different, but the same was true in other timelines as well, even on Pia. Perhaps their augmentations protected them a bit too much.

Alyx tilted their head in curiosity, “So that’s not what happened for you, huh?”

Ploht shook their head. They peered down at the sheet of paper on the table, detailing the line breaking into several.

“In my timeline,” they referred to the single line prior to the crux, “we knew of the Phexython. However,” they dragged their finger to one of the branches, “it’s corrupting our world, starting with our water. In some timelines, it already succeeded.”

“So it’s true? Randomness derives these crux events. When there is no consistent outcome, all potential outcomes become true, and we are a product of that?”

“What do you mean by randomness?”

Alyx’s tired features grew resentful after that statement. For a moment, it felt as if the work they spent the past millennia on was needless. How did such a naive version of themselves come equipped with such power and not a moniker of knowledge?

The devices implanted into Ploht’s body pinged in their mind for a moment.

Alyx scrounged up a separate piece of paper and a drawing utensil, “If we were to consider a system with a finite number of variables,” they drew a circle and placed three dots inside it. “No matter which way I push one of these variables, each one inside will have a response that we can calculate. Assuming an existing position of each one, the future is predetermined.” Alyx scribbled arrows leading from each dot in random directions, drawing paths bouncing off the inner walls of the circle.

They picked their pencil off the page, grabbing it on each end and turning their hands in opposite directions. A slit in the middle of the pencil separated it ever so slightly before clicking back into place. The tip now drew a red line, “But if there happened to be a variable that was truly random, then it would infect the other variables with randomness.” They drew a red dot within the circle, a question mark next to it. “We can’t know the state of something truly random. And for that reason, when it interacts with the system, the system changes in a way that can’t be calculated precisely.”

Resetting the pencil, they drew two new circles exactly the same as the first on another page, all with the same three dots plus the fourth now in black. The fourth dot on the two new pages were in different positions now, each with individual arrows that lead off them. “If there were two potential directions that this random variable could go, then both become true, and everything becomes predetermined again. Forever forward, until the next truly random event.”

Ploht looked at it for a moment before requesting the pencil from Alyx. They gave it away begrudgingly, but Ploht remained unhindered before lightly touching the page. Specifically, they drew a point outside the circle, “Randomness comes from here.” They drew a line piercing into the inner portion of the circle. “It doesn’t have to be random, your system just has to be unaware of it.”

Alyx stared for a moment, realizing the connotation of such an effect. Their mind wandered before Ploht broke the brief silence, “Also, I’m not as naive as you take me.” Alyx snapped a glare at them. A quick inspection garnered a lot of information: this alien version of themselves was riddled with internal technologies, maybe even some arcane. Chances are, Ploht could read their mind.

Finally relaxing, succumbing to a feeling of inferiority. “Why are you here then, oh, brilliant maestro?”

Ploht flipped the page over, drawing a single line. The line then branched off into three, and each of those three broke into two more, ending with a total of six final points. He began labeling each one, the first original line as “S-X”.

Once they finished their work, they pointed to the bottom line labeled “S-X.2.1” and proclaimed, “I’m from here. I’m going to each one of these timelines. They’re broken down by simple phrasing. S-X was the initial line, S-X.2 is the third recorded line after a crux event considering that zero was the first, and S-X.2.1 is the second line after that.”

Ploht’s cold glare wavered once more after Alyx circled a portion of the page and spoke up. A piece that had been missing seemingly clicked into place, unlocking years worth of forsaken knowledge all in a single moment.

“That’s not a phrase,” Alyx chuckled, “that’s a formula.”

—

The android with orange eyes went to their quarters, a small chamber in which it was able to recharge. Within its room was a large orange hololight screen, scattered with different messages, symbols, and numerical literature.

The robot used its mechanical fingers to tap away at the keys, contacting and interacting with someone far away. Truthfully, the android was aware of so much more than the people it followed, though being never prompted kept such information cloaked and stowed away.

Understood, would you like me to relay this to the other Primaries? The android’s assistant’s message appeared on screen. With inhuman reflexes, the robot typed back, Please do, yes.
`}</Text>
  </div>
)

export const metadata = {
  title: 'Discovery',
  author: 'Orident',
  date: 'Feb 15, 2025',
  accent: '#ff9600',
  foreground: 'black',
  id: 'hyperion',
  snippet: `The footsteps were heavy against the metallic floor of the think tank. Alyx didn’t even turn their head from their blueprints before telling the android, “I have some calculations I want to pass by you, could you-” “I think there’s something you’d like to see,” the android interrupted. Startled, Alyx turned around. Their brows furrowed as they looked into a pair of eyes that matched their own, down to the finest detail. They basked in the odd situation for a moment, checking every feature of this similar being.` 
}

export default Story;
