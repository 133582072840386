import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.2.0</Title>
    <Title order={3} fs="bold">Rev 20-12</Title>
    <Text>{`The boy with yellow eyes sat alone in his bunk. The air on the ship felt cold and lonely.

It had been only a couple of days since he began boarding, and the full trip was meant to take a little less than a week. When he first boarded, he was drowning in curiosity and wonder. His mind raced at the thought of such a massive device pulling them all the way through space, landing so precisely and so far away.

Inside his bunk, he had a suit designed to handle the void, for when they finally land and the trek between the surface and the facility. However, it’d also be used in case anything bad were to happen.

Hopefully nothing bad happened, as the suit seemed like it was only moments from falling apart. Not to mention, it wasn’t even close to his size.

The more he peered through the ship, specifically what he was allowed to see, the more worried he grew. The accessories of the vessel, such as the doors and the walls, seemed hastily slapped together. Albeit, he didn’t mind the doors nor the walls, but he did fear that the rest of the ship was designed in a similar way.

He washed his mind of those thoughts, rubbing his hands together. When he opened them, a small yellow flame appeared in his palms, lightly flickering to life.

Gently, he whispered to it. Gently, the flame whispered back. Tiny letters strung together in the glittering bits of flame that flew away. His eyes grew somber, “I miss her,” he said aloud.

Though the yellow flame emitted no heat, it felt warm to him. Somebody spoke, somebody or something from far away. Perhaps even somewhere that he nor anybody else really could understand. This soul that he spoke to emanated with power, yet its words were warm and welcoming.

It reminded him of the woman with purple eyes. The way she spoke to him.

His longing for Wendi grew rapidly, missing the weird wasteland he called home. It was always so odd to him how others worried so much about mortal belongings; houses, fancy items, the value of whatever they may be holding. The boy would have traded all of that away for the warmth that Wendi offered him at night, or the sensation her fur brought to his fingertips.

Write her a message, the flame whispered to him.

His eyes widened with epiphany, stunned by the sudden opportunity. Immediately, a small weave began to appear in front of him. The substance was awkward and colorless, something similar to hardened clay. However, he managed to weave it together in such a way that it resembled a fibrous sheet of parchment. His mind ran miles as words magically carved themselves into the floating paper.

You’ll never believe what I’ve seen so far! We’ve already broken the atmosphere; I’m a bit surprised since the ship is barely holding together. I would’ve thought it was unsafe at first glance, but they do this a lot so it must be fine. My bunk is really cold right now, but I’m sure once we get to the moon we’ll be warm again. I’m so excited to see what’s there! It sounds like they do really cool research there; I wonder if they can fix what’s going on back home.

The words carved in as fast as his thoughts paced around the room; quickly, he ran out of room on his magical page. He was multitasking, trying to continue to speak to the flame in his hands while conjuring another sheet of paper. He continued to write without ever laying a finger on the gray magical matter.

After nearly half a dozen pages of writing, he let out a long sigh of relief. He used his mind to fold the magical paper as it dexterously floated into his coat pocket.

Thank you, he thought in his head, speaking to this flame that led somewhere that vision, dreams even, could never take him.

—

The ship’s landing was rigid, bumpy, and borderline dangerous. The ship squealed for mercy when it touched the lunar surface, the limbs that held the vessel considering giving out right then and there.

The boy with yellow eyes looked up from the magical illusions he was fiddling with since the fire in his hands burned out. Mana was growing quite thin around this area, he took note unto himself. His big eyes darted to the door of his bunk opening, sliding into the roof of his corridor. A man with thick-framed glasses and a long white coat greeted him from behind the disappearing door, “Welcome to Walteria.”

Immediately he ran out the door, past the man, “Sorry!” he yelped as he brushed past. Cunningly navigating the ship’s various hallways and mechanisms, he burst out the exit bay and onto the lunar surface.

A number of people watched him as he walked out; each one was begrudgingly dawning their poorly made suits, awestruck by a brave child who just walked out onto the moon with no special equipment. At least, as far as they were aware.

He, without a second thought, immediately dashed to the large facility in front of them. A clear wall separated the warm artificial atmosphere from the cold void of space; he whispered an incantation that allowed him to breathe and ignore such a void as he ran. His mind was far away from things as meaningless as breathing, though.

His small frame slid through the airlock before it was fully open. Fidgeting with a small illusion between his fingers while waiting for the airlock mechanism to complete, he jumped through the final door so quickly that he accidentally floated through telekinetically.

The building wasn’t massive in comparison to standard buildings, though it was quite a marvel for those built on interplanetary rock. It consisted of fabric tent-like tubes that lead from dome to dome. Each hemisphere was made of metallic triangles, some of which were replaced with glass, revealing the operations going on inside. Scientists and researchers staring through microscopes, children his age exploring the area, documents being written and recorded.

He barely contained all of his energy, accidentally illuminating his silhouette out of habit. The glowing boy stood at the entrance of the largest hemisphere some 50 meters from the airlock, searching impatiently for a response.

Over what felt like hours for him, he heard the clicks and whirs of a mechanism within the door slide and shift. Finally, four of the triangular wall-pieces shifted away, revealing a woman with a long white coat standing behind them. She was smiling, looking far above the boy’s head, “Welcome in! I’m so excited-”

Her voice trailed off as she realized that almost nobody had evacuated the ship yet. She stared puzzlingly, finally moving her gaze down to the boy with yellow eyes, literally glowing eyes, his hands quickly meddling with each other close to his chest. “Hi,” he said, his expression never wavering. “May I come in?”

The woman cocked her head inquisitively, before stepping to the side, “Uh, of course. Please, come in. I’ll…” she thought for a second, not sure how to approach the situation. “I’ll be giving a tour once the remaining visitors enter.”

The boy jolted past her at unreasonable speeds, gently observing every single feature of the room without ever laying a finger. Her coat and her long brown hair still settling from the wind force the boy created running by, she looked at another one of the researchers scattered about. She raised a single eyebrow, silently asking questions like, “How!?” and “Huh!?” with her expressions.

Thoughtfully, the researcher responded with a shrug and large eyes. She finally lifted her glasses, pinching the bridge of her nose with her fingers. She chose not to fully process the situation and continued to the airlock so she could greet the remaining visitors.

The boy with yellow eyes continued mentally rummaging through the room before finally making his way to another hemisphere. This one was empty, large glass tubes scattered around, large enough that he could lay down in the center and never touch a side. They stretched from floor to ceiling, a small vented base on bottom. He immediately inspected it, seeing if he could figure out what the mechanisms inside it did or what its purpose may be, though he was interrupted by the door to his flank opening.

Habitually, he turned invisible and made himself small. Two researchers strolled in; one short older man with little gray hair and thin, short glasses walked next to a taller, skinnier man with well-kept hair, a trimmed beard, and rich brown eyes.

“I’m telling you that they’re a problem, Anvil,” the older man barked as they moved through the room.

“I know, doctor. I’m trying to-” the taller gentleman said before they disappeared into the next room over. The boy froze for the smallest of moments before impulsive darting through the small opening in the door to follow them, both silent and invisible.

“Subject 3310 has been nothing but trouble. She barely listens to any amount of instruction,” the older man cried. “She bit me when I attempted to instruct her!”

“But she’s paired with subject 7302, who is quite responsive,” the taller man said with little expression. Although, through the cold glaze that covered his eyes, the boy could sense a bit of worry. “We can’t remove just one of them, it would be a nightmare.”

“Then remove both of them, I don’t care. We have plenty of responsive subjects. Hell, a mute subject is a pain, anyways.”

Before the boy could take a long second to ponder, they moved into the next room; a large cafeteria area riddled with kids and young adults, each one adorned with a skin-tight grayscale suit. They looked as if they were constructed from rubber and spandex, but the boy was much more perplexed by the mechanism in each one’s chest: a small apparatus that surrounded a floating pink crystal.

The kids here didn’t share much for expression or conversation. One by one, they each moved through the serving table as if on a conveyor belt, moving in perfect unison and synchrony.

All, of course, except for one girl. She was shoving through crowds, disrupting the natural marching order that everybody was in. She wasn’t rude or forceful, though she stuck out quite well. Following close behind her was a boy with straight black hair and green eyes, saying not a word as they passed through.

This place feels lifeless, the boy thought to himself. What are they all doing here?

The boy attempted to navigate the room, though the mass number of people, including the two researchers that had just entered and fallen silent, were making him nervous. Without thinking much, he attempted to find a corner without as many warm bodies, though a researcher crouching down in front of him made him jump, knocking him into a table next to him where four kids were sitting, eating silently.

The children at the table didn’t move, flinch, or process the sound at all. Each one continued eating; in fact, they were eating in unison with each other. A chill went down the boy's spine. 

His feeling of relief dissipated when he looked across the room: the disorderly girl followed by the boy was making eye contact with him. He looked down, he was still invisible. He looked from side to side, freezing up, searching for something to do. Before he knew it, the boy was then looking at him.

The boy with yellow eyes shook, his breathing halted. The two looked away and continued forward with what they were doing. Before they turned away, the boy mustered up enough courage to utter a subtle spell, enhancing his eyes so he could see the small embroidered number on their collar bones.

He ran out of the room through an opening made by the visitors being guided by the woman who answered the door. He pondered to himself what he read while he inconspicuously merged with the crowd.

Subject 3310 and 7302.`}</Text>
  </div>
)

export const metadata = {
  title: 'Message',
  author: 'Orident',
  date: 'Feb 15, 2025',
  accent: '#ffff00',
  foreground: 'black',
  id: 'worldless',
  snippet: `The boy with yellow eyes sat alone in his bunk. The air on the ship felt cold and lonely. It had been only a couple of days since he began boarding, and the full trip was meant to take a little less than a week. When he first boarded, he was drowning in curiosity and wonder. His mind raced at the thought of such a massive device pulling them all the way through space, landing so precisely and so far away.` 
}

export default Story;
