import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.0.0</Title>
    <Title order={3} fs="bold">2024</Title>
    <Text>{`“Where am I going to find one?” Tori spoke to the small rodent on her shoulder. “I think we need to build one.”

She peered down at her phone with furrowed brows and utter determination. She wasn’t positive who exactly she was speaking to; somebody allegedly named Eko before, and now somebody who called themselves Rain.

These names shifted over and over, and she really had no control over who she had the chance to speak to. Beyond that even, there were no titles or identities given, just strings of text, one after the other.

The android with orange eyes tasked her with communication, and everybody else agreed to it. At least she didn’t have to talk to a fire anymore, that was quite the bother.

“You know, buddy, these guys are pretty cool.” She put a hand to her shoulder, allowing the Weasel to climb on. She lowered it to the floor, allowing it to scout around her dorm. “You and I are going to figure out how to build this thing, mark my words.”

The Weasel looked at her with confident eyes. She gave a determined smile, lifting her fist in front of it. It jumped up on its hind legs, placing both paws against her knuckles, giving her its equivalent of a fist-bump.

“You know where to go, little guy,” she said as she cracked the door open. Swiftly, the Weasel scurried out of the room.

—

Max wandered through his lucid mind while his body drifted to sleep. The world was a swirl of colors and he did no more than float in the center of it all.

This expanse was comforting to him. While the world beyond his eyelids was confusing, erupting with confrontation, this place inside his mind was silent. No opinions to shove him in one direction or the other, nobody to impress and nothing to conform to. It was simple, it was basic, it all made sense, as there was nothing to consider.

This is not inside your mind, Max. This is far more than that.

He jolted at the thoughts caressing his mind. Out of nowhere, the swirling colors began to manifest a real image; tangible grass, a visible blue sky, fluffy white clouds barely blocking the blinding rays of sunlight. The breeze here was warm, almost comforting. However, the giant floating eyeball directly in front of him staring him down was not.

The world around him aged in hyperspeed, as if playing a video at 100 times speed. A tree in the distance grew to nearly twice the height before a flash of lightning brought it down, all in the matter of seconds. The spinning sun and moon turned into just a blur.

Through it all, the floating eyeball continued to stare, peering into his mind. Even when it didn’t speak, it felt like it was melting the walls of his conscience in search of something valuable.

Don’t worry. I will show you.

Max’s body was shunted through the air, leaving the eyeball and its weird body in the grass where he once stood.

Time continued to run forward, he couldn’t tell at all how long had passed at this point, nor where he was at this moment. He tumbled through the air as if picked up by a tornado, before finally being flung on to a large metal platform. He went from moving at massive speeds to an utter stop, though no pain rushed through his body.

He brushed his hands over his face, feeling his hair and his beard, checking to make sure he was still a person of some type. Surprised but relieved, he was still completely himself. There was something oddly comforting about his complexion; not what he looked like, but rather the fact that no matter what happened in whatever world he was in, he was the consistent anchor. He was always the same.

Time had finally stopped spinning, giving him a moment to gather himself and look around. The platform he landed on seemed to be suspended high in the air, above a mountain range. Its peaks were below him, and below that even was a river carving a passage between the giant masses.

On top of the platform were massive piles of junk. His mind failed to make out any details of his surroundings, or what exactly it all was. Not that they were obscured in any way, he just simply couldn’t process the information he was receiving. Instead, all he could tell was that the piles reached some odd four times his height.

At the center, there was a small opening that seemed something like a door, as if the junk was used to create a tent. Behind him, on the opposite side of the platform, was the exact same concept but with different pieces of imperceivable junk. He considered exploring, but somebody floated up before he could.

The creature was humanoid, shrouded by a violet cloak that cast a shadow over their face. Two piercing red eyes were visible, but nothing else. It was holding something with a single, mechanical arm. Seemingly once human, later changed out for machines.

This creature was accompanied by a flying machine, a small spherical device with a large screen on its front and a propeller on its top side. The creature spoke to it in a deep, monotone voice, “Buaris, print records.”

The robot ejected small pieces of organized parchment covered in symbols that Max didn’t understand. The odd person reached out a mechanical hand and ripped each one out of the robot, looked at them briefly, then walked into one of the giant piles of junk. Specifically, they walked directly through Max, phasing intangibly through him.

This is where everything began. The voice felt like it was dragging a nail down his spine.

A bright light catches Max’s gaze, scraping against the distant atmosphere. Something fell from the sky, seemingly something massive based on how far away it is, but Max couldn’t fully gauge it.

It landed far away, beyond where Max could see. He watched it land, and some time later, he heard a distant boom from its collision. He stared in awe.

Time suddenly sped up again. 

Max began getting dizzy, clutching his hair, “What are you doing!? Why am I here, and where is here?”

Time stopped completely, almost in response to his pleas. He shakily released his hair from his hands, turning around to see the cloaked figure looking at him from only inches away. Max fell backwards from surprise and a sudden flow of paranoia.

Unlike last time, the thing perceived him. In fact, they kneeled down to get in close while Max was on his back, propped up by his elbows. Their monotone voice reverberated through him, “I’m giving you clarity.”

—

Tori’s dorm room was a disaster. The hamper full of dirty clothes was beginning to meld with the pile of clothes she considered clean, sprawled out across the floor. Books and random objects covered every flat surface, including the shelf created by the full garbage can.

She tinkered away at her desk, smashing small bolts into poorly-fitting holes, predominately using brute force over mechanical leverage. The Weasel had been trickling in small parts as she requested, ranging from a large plank of flat wood to some small squishy wheels.

“Oh, come on. How do people even do this?” She continued slamming each part together, partially damaging each piece into submission.

The Weasel glared nervously at her, then at the door. Tori was too preoccupied to get a read on its expression, but it discovered something she needed to know. It squeaked, placing its paws up on Tori’s calves, but the sound was drowned out by her grunting and slamming.

After one more sideways crank, she stopped to admire her work. She had fastened two metal braces to the plank of wood, followed by two wheels on each one. She looked at the terribly crude device, “Skateboard.”

The squeaking didn’t get the small creature anywhere, as she immediately bolted out of her room to test her new device. Luckily, it was able to preemptively make its way onto her bed via the cover strung half across the room and leap into her pocket before she left.

She used her first step outside to leap, placing the board dexterously beneath her boots while she soared. In her mind, she watched as she positioned her feet on each end, keeping her mass centered where she felt most sturdy: directly above the wheels. She used her incredible reflexes to admire her work before she stomped the board to the ground.

The moment the wheels made contact, the board slipped out from under her. Her face made a direct line to the concrete.

The Weasel, still concerned with the burden of its information, snickered at her.

As she got up to regain herself, more determined than ever, she inspected her board for damage. It was, in fact, covered in cracks and fissures. She furrowed her brows with inquisition before nodding confirmingly, “Yep, looks good. Let’s try that again.”

—

Max stood himself up, straightening out his back and composing himself artificially. He did his best to hide his tremendous fear.

“Who are you?” Max stared through the corner of his crooked glasses, looking at what would be the creature’s forehead, a trick he used to simulate eye contact.

The creature walked past Max and into the giant pile of junk, time beginning to swirl around him again. “I’m the Doctor.”

Max staggered, clumsily following him into the giant pile of junk. The inside was quite messy as well, but ultimately showed some semblance of organization. Paper covered in odd symbols were nicely stacked, but those neat stacks were scattered in random locations.

The robot with a propeller floated at the edge of the room, assuming you could call this a room. A large reinforced slot prevented the junk from piling all the way over, creating a small window that saw into the horizon. The terrain below them was ragged and bumpy, covered in varying elevations.

“I created many things, but it all led to only one creation,” the Doctor spoke while tapping on the screen of the robot. The robot’s screen was black with white text sprawling across. “It was all for this.”

As his last word echoed, time stopped once more. However, this time left Max much more confused than before.

Existence was somehow split into three parts; as if his vision was trying to separate itself. The colors began to break apart, his physical understanding of the world washed away in mere moments.

“This was the crux event I manufactured,” the Doctor continued. They still didn’t look at Max; the creature was still preoccupied with tapping on the robot’s screen. Buaris was its name, Max recalled.

“What is a-” Max attempted to speak, but he was interrupted by being thrown through the floor and into the river below them.

As he fell hundreds, maybe thousands of meters, he watched existence’s fissures begin to repair themselves while time spiraled backwards. He caught a glimpse of something racing in and out of the river beneath him, though he collided with the surface before he could figure out what it was.

Crashing through the water, quickly sinking to the bottom, he saw the most incomprehensible thing thus far.

Some creature, something itching for life, something so hungry it felt like it was consuming him just by being present. Its figure was incomprehensible, as if it was a part of multiple planes, multiple dimensions, something. 

Everything seemed to warp around it, his mind spun when he looked down at it. There were no words that Max could use to describe what it looked like. There had to be something. Something like…

The Phexython, Max. Max. Max, the Phex- Max! MAX!

—

Max jolted awake to the sight of Tori leaning over him, covered in bruises and blood. His worry for Tori’s condition clashed with his groggy attempt to conjure his composure. He rubbed his face with agitated hands, rubbed his eyes, then grabbed his glasses from the shelf. He looked at her through the lens, “Tori, wh-what happened to you!?”

Tori smiled, hugged him painfully tight for a brief moment, pushed him away, then spun happily across the room. She barked with excitement, “Dude! You were totally screaming in your sleep. You’re lucky my little guy was able to find you, I was getting scared there for a bit!”

Max’s eyes dilated, trying to focus on what happened in his dream.

“Yo, but I ended up learning how to skateboard while you were snoozin’!” Tori grabbed two halves of her device off the ground, “I’m gonna make another one tomorrow, these things are awesome.”

`}</Text>
  </div>
)

export const metadata = {
  title: 'Dream',
  author: 'Orident',
  date: 'Feb 15, 2025',
  accent: '#ff0000',
  foreground: 'black',
  id: 'revelations',
  snippet: `“Where am I going to find one?” Tori spoke to the small rodent on her shoulder. “I think we need to build one." She peered down at her phone with furrowed brows and utter determination. She wasn’t positive who exactly she was speaking to; somebody allegedly named Eko before, and now somebody who called themselves Rain. These names shifted over and over, and she really had no control over who she had the chance to speak to. Beyond that even, there were no titles or identities given, just strings of text, one after the other.` 
}

export default Story;
