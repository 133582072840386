import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.1.1</Title>
    <Title order={3} fs="bold">1st Year Prior</Title>
    <Text>{`Cyreil made his way to his recent battlefield. What was it, perhaps a week since the last incident here?

He was approaching the Bridge of the Gods. The woman with purple eyes called him over for some type of meeting, a tea party of sorts. He could only presume that his ear would be gnawed on, as it always is. And as per usual, he planned not to listen, just to civilly attend. He was sure that the others there would be able to cloak his disinterest.

He stopped at the bank of the river, the one that soared beneath the bridge. He wandered the spot where he stood, where he ended the onslaught single-handedly. Nobody above his ranks had the gall to scold him for not listening to orders, fortunately. The formula is simple: if it works, there’s nothing to be angry about. His subordination was not a part of its success, and it won’t be part of the deal.

He crouched down, peering through the crystalline water that had since thawed out and washed away. He checked his palms, the runes scarring his hands were vibrant to him. Most commonfolk were ignorant of their meaning, but some may know too much. And for that, he kept his hands faced inward.

Every single person on that ship died at your capable hands.

Cyreil was so preoccupied in thought that he didn’t feel the gentle caress of the tattoos on his neck. They licked his ears, sending a jolt down his spine. He was immediately in disbelief when they spoke, but his eyes made them trustworthy: a body floated up from the water. A lifeless body, one dawning makeshift garments made from scraps and junk. The strap on a pair of goggles had snapped and the goggles drifted slowly away from it.

The foot of the body was caught on the bank, it faced directly down, soaked. One of the body’s pant legs were still tucked into their boot, the other had been torn to shreds and seemingly singed. Blood sheepishly flowed from the body, now sunken and losing its flesh to the world around it.

Cyreil gagged in his mind, appalled by the sight.

Nearly a dozen folk, just like this one.
He turned away, considering gathering the strength to turn the body’s head and see what there is to see. This was a real person; the idea of them having a life before this, or worse, a future following this. The idea impacted his chest, pulling his breath away from him.

However, once he glanced back at it, the body was gone. No blood, no goggles, no traces that it ever existed.

Do not misunderstand. What you did was necessary, regardless of the outcomes. This is what your power looks like. Embrace it.

His breath became shallow. He pondered the idea unto himself.

Imagine it, what would have happened if you didn’t? The wall you created barely held up, what would their net have done? Perhaps killed everybody instead of the dozen. 

He looked up to dozens of bodies drifting slowly down the river, maybe 50. Each one was getting dragged up and down by its somber tides. Pieces of the ship, the net, members of both his side and theirs, all of them dead. When he focused in, he saw the one body that floated up in front of him, still face down in the water.

Be the Conductor of your wills, do what must be done. Especially when no other flaunts the bravery to do so.

Cyreil stood up, shaking himself off. The feathers on his wings were ruffled, pushed out like goosebumps. He took a long, tailored breath, willing them back into their resting position. He looked over the horizon, “What I did was necessary.”

And if you must, you will do it again.

He squinted his eyes with the intent to rebel, but quickly gave in to agreement. “And if I must, I will do it again.”

—

The bridge was no more than a kilometer of silent steps. His head was full of thought, but none scattered. He was in control of himself at this moment.

As the path transitioned from dirt and grass to stable cobble, the silver tattoos burrowed themselves in his shirt, driving themselves down his shoulder blades and behind his wings, where they slept.

Half way along the bridge, he saw the woman with purple eyes. She was leaned over, inspecting something in the center of it. It took him a moment to figure out what it was: a small hole going entirely through the bridge, perhaps a couple of meters across.

The hole had been there for some time. Memories flooded back to him: the woman falling in the water, the boy crashing through the bridge, the giant platform falling out of the sky; it was a blur at this point. The blood in the water ran thick through her eyes.

When he finally approached speaking distance with her, she cocked her head up at him, “Stay away.”

He stopped immediately, but simply raised a single eyebrow at her. She sighed, brushed the rim of the destruction with her fingertips, then stood up. “I’m sorry. Thank you for seeing me.”

He rested his gaze, waiting for her to take a lead in one direction or another. She did, in fact, lead back the way he came. However, the path led from the South, whereas she wandered West.

“It’s needless to state the amount of history between us,” she said, facing away from him as they marched.

“Needless, indeed,” he said in response. He didn’t see her face furrow, but he did hear her take a labored breath.

She ignored him, “But there’s some powers at play that I think you need to be aware of.”

“Treat me like a fool, why don’t you. Pretend I don’t already know what’s at play.”

She stomped her foot down and spun, “Listen, Cyreil. I understand how powerful you are. I understand what you’re capable of, and I’m aware that nobody else does. But you are not going to put up with this on your own.”

He stared blankly at her, leading her to believe that her words did nothing to pierce him. There was something he was waiting for, he was sure she was going to say it.

She continued walking, barking at Cyreil as he followed, “The Phexython’s body needs to be dealt with, one way or another. Containment doesn’t seem possible, and there’s a number of people watering at the mouth in search of its immense power. However, you and I both know-”

“It can’t be contained, like you said. I’m glad we finally agreed.”

“You’re right. Its body has been hidden, but we’re not sure how long it’ll last before somebody finds it. It could be years, it could be mere moments.” The woman led them deep into the forest, sprawling with shimmering lights and giant fungus. The mana here was enriched, nearly so sweet it was sickening. Cyreil entrenched himself in it, listening to every whisper it offered to him. She continued as she pushed the various branches out of her face, “But now we have a new problem to contend with.”

Cyreil watched the lights around him, waiting for a signal from the mana. He was silent, though he was asking questions, casting divinations, waiting for it to tell him something important, “And what would that be?”

“The Phexython’s body will contaminate the terra, the water, the air. It will corrupt every living thing it can get its hands on.”

“She’s right,” the spell whispered to him. His heart sank a bit at the thought of it. He asked the mana a new question: Is Alyx still trying to harness it?

The mana cut itself short; no response. Cyreil knew a lot of things about Alyx, but they were one to keep much to themselves. They were both quite stalwart, and he was confident that if Alyx decided it necessary, they would be quick to go back on a promise. He asked the woman, “Is there somebody trying to harness this creature still? Anybody in particular?”

They approached a small campsite and she paused, seating herself on one of the logs dotted around. She crossed her arms and nodded, “And you know exactly who that is.”

—

No more than 15 minutes passed, though every second was grueling. A small, crude set of ceramics were dotted around on flat stumps. She had spent some time grabbing water from ponds, fetching leaves and mushrooms, boiling everything over a small, rudimentary platform hung over the flame.

Oddly enough, the flames were abnormal: they burned a deep shade of violet. It was warm, hot to the touch like normal. He even tested a small flicker of ice against it, but it burst into steam as he would have expected. He chalked it up to some odd illusion.

Once her makeshift kettle began to scream, she poured a cup for him and herself. Cyreil knew better than to accept concoctions from strangers, but she showed every ingredient to him as she worked. This wasn’t some kind of ploy, this may have been her attempting to relax while they talk.

This was greatly unappealing to Cyreil, the idea of relaxing.

“A lot of people have attempted to condemn this monstrosity, and none have succeeded,” she said, holding her cup with both hands, hunched towards the flames.

“How many times has such a thing happened,” he asked, setting his tea on the log next to him.

She seemed flustered for a moment. She stood up straight and regained her composure quickly, “More of a simulative nature. Divination, simulations, the likes. The math just doesn’t check out.”

He cocked his head at her, “So you brought me here to tell me it’s hopeless?”

“No, I brought you here to tell you we found something.”

He was motioning for his tea, but he stopped to look at her. As determined as he was, he didn’t expect something so certain. He silently awaited her response, his hand hovering above his cup of tea.

She gestured for him to grab the tea. He patiently took a sip of it, surprised by its flavor. It was somewhat floral, quite bitter. Exactly what he would have preferred. His pleasant demeanor must have shown, as she flashed a faint remnant of a smile, “I think we have more in common than you think we do.”

He squirmed against the concept. He saw the relationship between them as strenuous at best, though he respected her for her methodology. For as long as he’s known her, she has remained focused, headstrong, and capable.

He still didn’t like her.

After finishing half of her tea, she made her way back to the original topic, “It may never be destroyed.” Her bright purple eyes reflected in the ripples of her tea. “However, we may be able to hide it and encase it. Permanently freeze it, even.”

—

The woman stayed at the fire for some time after Cyreil left. She spoke to it, and at times, words would appear in its embers. They were hard to make out sometimes, but she was used to the effect. She had grown very capable of reading it.

Somebody spoke to her through these flames, though who and what was still a mystery to her and the others. She hadn’t quite grown to trust this fire, but some of the voices seemed appeasing.

There was one person she had grown a strong liking to: somebody she believed to be the self-proclaimed Skye. Everything she was told by these flames was chaotic, inconsistent, and difficult to parse. However, Skye’s voice became something of a tether for her. Their voices were consistent, their questions were meaningful, and their answers felt thoughtful.

Somebody new was talking to her. She did her best to sound composed as she responded, though deep down, she was quite overwhelmed by the numerous responses and questions. It felt like whatever these embers spoke of was either a figment or a part of some greater universe.

There might be more than two of you.
A toast to world domination.
All eyes seem to be watchful.
Wait, what?
Well, it’s all in good fun.

Sometimes it felt like a conversation was happening in front of her, but it all felt like it was coming through one voice. As if somebody was conversing with themselves. However, the voices would come so fast that once a sentence would appear, overwriting the last before she could read it out. It was nightmarish at times.

As night fell over the canopy above her and the glowing mushrooms were transitioning to being her only light source outside the fire, she decided to pack up for the night and head back to her quarters. She never said goodbye or offered much pleasantry, and as such, she didn’t tonight.

But she did turn around to the last thing it said. Its scattered embers floated through the air, creating their borderline incoherent message as per usual. These ones spoke out to her, though; something gave her a chill down her spine, begging for her attention. She read them and quietly gasped; Alyx would be slain by the Godslayer.

The fire went dim as the connection closed for the night, leaving the embers embedded where they lay.`}</Text>
  </div>
)

export const metadata = {
  title: 'Tea',
  author: 'Orident',
  date: 'Feb 15, 2025',
  accent: '#9600ff',
  id: 'dystopia',
  snippet: `Cyreil made his way to his recent battlefield. What was it, perhaps a week since the last incident here? He was approaching the Bridge of the Gods. The woman with purple eyes called him over for some type of meeting, a tea party of sorts. He could only presume that his ear would be gnawed on, as it always is. And as per usual, he planned not to listen, just to civilly attend. He was sure that the others there would be able to cloak his disinterest.` 
}

export default Story;
