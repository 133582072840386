import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.0.1</Title>
    <Title order={3} fs="bold">Year 174</Title>
    <Text>{`The subtle sound of crunches beneath their steps were no less than soothing against their bare feet. A woman, middle aged in her appearance, and a small child holding her hand as they walk.

Her slender blue skin was quite resistant to the world around her, though her child was much more fragile in comparison. He would trip from time to time, wincing from small cuts on his ankles and feet. His mother was quick to correct him, while still reassuring him that with every cut gained, the next one will be a bit more manageable.

They were in dense brush, surrounded by wide yellow trees decorated with dark grey leaves, reaching up to nearly 100 meters tall. It had been a few weeks since they had eaten; a month would push what’s healthy for them, so food was of utmost priority at this moment.

As they navigated the thicket, she winced all three of her yellow eyes, peering deep through the dense forest. Her ears were extremely sensitive to sound, but nothing was making the sound she was looking for. It may be a few more days of travel before they find something.

“A few more days at least,” she said to the young child. He didn’t seem phased by the notion. Rather, he was curious of the world around them, the way that the trees swayed from side to side, the sounds they searched for. He wasn’t quite sure what sound that was, though he felt determined to find out.

—

Another few weeks passed, and no sounds fostered any hope.

Their metabolisms were slow, but they were expending more and more energy by moving at the pace that they were. Over the course of about a month, they had traveled from one end of the biome to the other.

Small creatures normally burrowed through the earth in predictable patterns, emitting minor tremors that they could sense. However, the ground had been silent, and they were low on time.

“Why are we here?” The boy asked inquisitively, seemingly unbothered by the turmoil they were going through. His mother made sure to inform him of the gravity of the situation, but the boy simply pondered rather than worry.

She nearly stopped in her tracks when she heard, but she caught back up to her normal pace again. “That’s quite the question,” she was hesitant to answer a seemingly unnecessary question at this moment. The boy continued staring forward, offering her the realization that the boy could see something that she couldn’t.

Perhaps it’s hopeless. And if it is, why not ponder what exists beyond us?

Her pace slowed down marginally as she released a long sigh, “In the beginning, there was but one. One single soul who wandered the world, edge to edge, discovering everything there is to discover.”

The boy finally broke eye contact with the horizon so he could look at her. She continued as she peered over yonder, “The world She walked was lifeless, barren, and numb. And so, She created life.”

The boy looked around them. First at the sky, then the sun, then the grass they walked on. He looked back at her, “Can She do anything she wants?”


The mother cocked her head for a moment in thought, “Yes, anything at all.”

“Is She mad at us?”


A guttural chill went down her spine. She squeezed his hand a bit harder than she meant to, quickly realizing and releasing. She composed herself, “No, She loves us, for we are Her children.”

“Then why are we so hungry?”

Her voice wavered and choked before she could get another word out. Truthfully, it was a question she wondered to herself, but not one she meant to pass down to him. She looked at him, putting a gentle hand on his cheek as they walked, “I promise, we’ll be okay.”

He looked at her with large eyes. His lips didn’t curl up nor down, leaving her wondering how he felt. It jostled her mind trying to figure out how he was feeling, perhaps even leave him with a moniker of comfort. However, she was interrupted by him pointing; a small clearing between the trees revealed some type of structure, one she’s never seen before.

More than that, somebody was approaching. Not a normal somebody. No, this person had bright orange hair and pale, pinkish skin, vibrant green eyes.

“Welcome, welcome! I will be your tender for today. My name is Okifaun,” said the winged creature with bright green eyes.

—

The doors of this building were made from the flesh of young trees, an idea that was mildly offputting to the woman.

Portions of the wall, even the furniture, were made of the same substances. However, the remainder of the place seemed to use things found beneath the grass. Varying minerals, though some of them seemed to be modified in ways she didn’t quite comprehend.

The area was decorated with a multitude of tables and chairs. At the far end was a bar, lined with containers that seemed to produce liquid through an odd mechanism on each one. He grabbed a transparent object, a concept she hadn’t considered before. Something with no color was new to her.

“Oh, you seem quite confused. I’ll give you a rundown on the place,” he said through a smirk. He levitated around, pointing at various odd and ends before bursting into a cloud of smoke and appearing somewhere else. “This is a cup,” poof, “these are barrels,” poof, “and this is ale.”

He slid two full cups across the bar, both of them landing perfectly in front of the woman and the boy. He looked at them with a small cloth in hand, rubbing the inside of another glass. “Don’t swap those glasses, not all ale is good for the young. However, you will likely metabolize it much better than a human would, so I wouldn’t be too perplexed.”

Both of them stared at the odd specimen, dumbfounded and lost about what had just happened. He snickered for a moment before appearing across the bar directly in front of them, “Don’t be shy, take a seat. I say, you must be hungry!” He snapped his fingers in perfect synchrony with a plume of smoke behind him.

A young man, something similar to the boy and the woman, with a slender figure and smooth blue skin, appeared from nothing. He draped cloth over his shoulders. One white piece that buttoned together, covering his torso and half of his arms, the other with no sleeves. He was holding a silver plate with a shining dome on top, only touching the tips of his fingers with it.

He seemed like he did not care much for being there.

“This will be your Waiter,” Oki said as he plucked the dome off the plate, revealing a huge mound of food that would surely feed them for another month. The woman’s eyes grew wide at the prospect, but didn’t reach out for it. Instead, the young man placed the plate down on the bar, serving them small eating utensils from his pocket.

“Bone apples,” he said in a monotone voice with a bland expression. Oki turned to him, raising an eyebrow, but ultimately shrugged and smiled back at the two across the bar.

“What’s going on?” The boy looked at everything, keeping his resolve as not to act without his mother confirming the situation, “where did all of this come from?”

His mother peered around for a moment while trying to recollect her thoughts. She felt overwhelmed by the new experiences and the endless waterfall of questions that entered her mind. A solution entered her mind, “So She does care for us.”

Oki choked back a giggle before quickly straightening out his expression. The woman didn’t notice, but the boy did.

With a new wash of content, the woman began to parse the food out for herself and her child, immediately taking large bites out of it. The boy stared with hesitation for a moment before finally taking his first bite.

He didn’t quite get the feeling of hope, but he did feel satisfaction in sustenance.

—

A very slow hour moved past them; an hour filled with slowly finishing their food, Okifaun answering questions that nobody asked, and the Waiter sighing as he completed each needless task decided by Oki.

Okifaun was levitating upside down when She burst through the doors. The boy and the woman were perplexed at first glance, concerned about the presence of another human, the odd creatures that Okifaun had been going on about.

“Hey, Perfection,” Okifaun blurted out, his hands dangling only inches from the ground. His tall green hat was orbiting around him, his curly hair hanging down towards the floor.

The woman froze where she sat, her eyes ever wide.

Oki’s gaze meandered to the woman and the boy before sudden realization fell on him, “Oh!” He levitated back to his feet, his hat magically floating back onto his head. “Apologies, yes. This is God. God, perhaps give a swift greeting to my new guests.”

The woman went to stand up, respecting the presence of her creator, but she was turned to dust before she could, along with the boy, the bar, and every trinket and piece of furniture inside.

Okifaun slouched and threw his head back, “Not again.” His head rolled around as he stared at Perfection through his eyebrows, “Do you have not a shred of empathy? Even a fae creature like myself would consider thinking about potentially feeling somewhat bad.”

The Waiter, still standing with his button-up shirt and vest, looked at Oki with no expression. However, Okifaun was able to tell that the complete lack of expression meant he was inquiring about the last thing said, obviously.

Oki straightened out, “I never requested triumph for my moral standing. I simply presumed that a God may hold weight to justice.”

She lifted off the ground, floating past the trees that were now disappearing behind her. Oki followed in suit, magically dragging the Waiter with him, hoisting him upside down. This specific lack of expression meant that he was having fun, surely.

She forced thoughts into his mind, The next attempt will start at dawn. Do not interfere.
`}</Text>
  </div>
)

export const metadata = {
  title: 'Bartender',
  author: 'Orident',
  date: 'Feb 15, 2025',
  accent: '#00ff00',
  foreground: 'black',
  id: 'perfection',
  snippet: `The subtle sound of crunches beneath their steps were no less than soothing against their bare feet. A woman, middle aged in her appearance, and a small child holding her hand as they walk. Her slender blue skin was quite resistant to the world around her, though her child was much more fragile in comparison. He would trip from time to time, wincing from small cuts on his ankles and feet. His mother was quick to correct him, while still reassuring him that with every cut gained, the next one will be a bit more manageable.`
}

export default Story;
