import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.2.1</Title>
    <Title order={3} fs="bold">7 AC</Title>
    <Text>{`The blue-eyed boy stood at the sill of Alyx’s door. The machine, after a tense moment, had finally calmed down to a light whir. Alyx took a few calm steps to meet him, crossing their arms as they looked down at them, head cocked.

“Impossible,” they said sternly after thinking for a few mere seconds. They followed the retort by retreating back into their home, fiddling with more machines, checking the time, sighing, and finally moving into the next room over to collect some paperwork.

The boy let himself in, “I mean, I feel pretty confident about it.” He began to follow Alyx around as they gathered their things for travel.

The home was made mostly of metals and stones, though a small amount of wood furnished the area. Nearly everything was made from one mechanism or another. A small container that used vacuum power and a special mixture of gasses to keep food preserved, a table that hovered a few inches above the ground, increasing its thrust force based on the amount of weight it was holding to preserve energy. A doohickey here, some trinket over there.

The boy wandered through, taking note of all of the random possessions and odd things, considered poking and prodding at different things. However, past experiences and an adversity to being electrocuted told him otherwise.

“I’d care to imagine that if I can take care of myself here, I can take care of myself in most places,” Alyx continued as they packed. “And besides that, I don’t have much time to investigate any further. If I was concerned with such an issue, I’d get to it once my contract is complete. I’m just saying that I don’t think it’s true.”

“After the other clones went to their respective timelines, weird things started happening,” the blue-eyed boy continued, meeting Alyx’s wandering eyes. “I keep in contact with them quite a bit, and it’s not sounding so good.”

“I have no more than a few minutes, speak quickly.”

“Uh,” the boy stuttered. Fumbling to gather his thoughts, he wavered for a moment, “I mean, you know what happened in Perfection. No signs of you in Revelations-”

Alyx interrupted him with a subtle click of a button that led to a blaring beep. The machine they were fiddling with earlier took up most of the room, covered in meters and timers, digital displays, and things that the boy was positive probably did something, maybe. Its Performer seemed to know everything it did, down to the dial, regardless.

Though, he did wonder from time to time if they just put it on the machine to look smarter.

“Go on,” Alxy gestured.

“Oh! Yeah, uh…” The boy waved his fingers through the air as if trying to draw the different timelines in the air. “In Worldless, you were spotted but then you were gone. Dystopia you is-”

“Time’s up. I need to go.” Alyx immediately pushed politely against the boy, “Excuse me.” Taking no consideration, they continued out the door.

“I- What are we going to do about it? I mean, do you have any ideas?”

“No, I do not,” they said as they levitated off the ground and into the horizon at phenomenal speeds. Their voice trailed off as they disappeared. 

“Oh, cool! Yeah, that was a good talk,” he rambled on to himself, “I actually really enjoy talking to you. I’m glad we could have a real heart-to-heart there. Just like you, I’d love to have a massive number of conversations with you, just like this one!” His sarcasm filled the empty air while he barked at the silhouette of where Alyx used to stand. Finally, he sighed and wandered off into the woods.

—

The boy stared at a deep blue flame, exhausted by his loneliness.

Normally this is when he’d check in on the others with a quick message, though he decided to just watch for now. No images, no text, just a cold flame sparkling in front of him.

His memories sat on his shoulders like lead pauldrons. When nobody was around, an image appeared to him over and over: the haunting light he saw when he first opened his eyes. A doorway of sorts, a single peephole into somewhere else.

Somebody stood in front of that door. Somebody he still sees in the flame from time to time.

“I wonder what would have changed,” he said quietly to himself, “if we never met.”

He maneuvered off the stump he was stationed on, scooching even closer to the heatless blue flame. He sat against the seat instead, wrapped into a ball with his eyes barely peaking over his knees, his arms wrapped tightly around.

The flame often showed its own images, but the blue-eyed boy decided to just imagine them instead. He relived his memories and asked his questions in his mind, putting the images into the fire with his imagination. For a stark moment, his world was whatever he wanted it to be.

“Sometimes, it’s hard having to be responsible.” He continued, as if speaking to the fire, “Like, I understand that we’re capable, and it’s important for us to protect those who aren’t, right? But… I wish we didn’t have to be those people.”

A single tear trickled down his cheek, that of which he wiped onto the fabric of his pants. “Why do all of my closest friends have to go away? Why do they have to go to some other place where I can’t see them? Where I can barely talk to them. If I had less powerful friends, would we be able to stick together?”

The air around him was frigid.

An ominous presence loomed over him, then a voice, “Nah, then they wouldn’t be able to do all the responsible stuff you gotta do, right?”

He nearly jumped out of his skin. The voice was soft but stern, a feminine voice. His eyes darted around as he realized he needed to cover the flame. He didn’t see anybody, but he also didn’t see anything to cover it with. His hands maneuvered to some branches, some leaves, that wouldn’t work…

A young adult woman slowly broke through the forest’s covering, a light crunch beneath her feet as she stepped. When she finally made her way to the small clearing, she saw the boy with blue eyes, standing in the center of six stumps, each with odd runic carvings in the top.

He was sitting on the fire.

When she met his gaze, she cocked her head with curiosity, “You’re glowing.”

He blushed for a second before responding, “Thank you! You are too!” His smile was rigid but convincing. She shrugged acceptingly before sitting on a stump. His eyes widened, though her presence was oddly comforting.

“What’s your name?” The girl reached her hand out to the boy, ready to make an acquaintance.

—

Alyx was floating through the air with utter determination, the river between mountains closing in.

Below the structure people referred to as the Bridge of the Gods was a coagulated body of water called the Abyss. As they descended towards it, a thin, clear film surrounded their face and head, spawning from a brace on their chestpiece. They dove in.

The Abyss was much deeper than a standard river would warrant; the contract he took requested that they find something at the bottom. Alyx was capable of surviving most, if not all, environmental conditions without much issue. They had over half a dozen augments beneath their skin and countless trinkets and artifacts swimming around in their nearly bottomless bag.

With absolute precision, they maneuvered each one perfectly. Moreso, each one was coded to their wants and habits, allowing them to change states purely on muscle memory.

A short augury escaped their fingertips before they penetrated the surface of the water, what are the details of my contract?

The spell slithered its way into their pockets, encasing itself around the small scroll in their pocket. The mana stamped an impression of the ink and injected it directly into their mind: There should be a substance called “Sludge” at the bottom of the Abyss. Please collect a small sample of it and bring it to me.

- Victor Quatron`}</Text>
  </div>
)

export const metadata = {
  title: 'Contract',
  author: 'Orident',
  date: 'Feb 15, 2025',
  accent: '#0096ff',
  id: 'utopia',
  snippet: `The blue-eyed boy stood at the sill of Alyx’s door. The machine, after a tense moment, had finally calmed down to a light whir. Alyx took a few calm steps to meet him, crossing their arms as they looked down at them, head cocked. “Impossible,” they said sternly after thinking for a few mere seconds. They followed the retort by retreating back into their home, fiddling with more machines, checking the time, sighing, and finally moving into the next room over to collect some paperwork. The boy let himself in, “I mean, I feel pretty confident about it.” He began to follow Alyx around as they gathered their things for travel.`
}

export default Story;
